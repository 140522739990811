import amplitude from 'amplitude-js';
import { getApp, WebAppName } from './env';
import { captureException } from './sentry';

const isProd = process.env.NODE_ENV === 'production';

// So it can be used on the client outside of react-world
window.amplitude = amplitude;

export const setOnce = (properties: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: boolean | number | string | unknown[] | object;
}) => {
  const identify = new amplitude.Identify();
  Object.keys(properties).forEach((key) => {
    const value = properties[key];
    identify.setOnce(key, value);
  });
  amplitude.getInstance().identify(identify);
};

// Doesn't throw errors
export const trackAmplitudeEvent = async (
  name: string,
  properties: { [key: string]: unknown }
) => {
  return new Promise<void>((resolve, reject) => {
    amplitude.getInstance().logEvent(
      name,
      {
        ...properties,
        path: window.location.pathname,
      },
      () => {
        resolve();
      },
      (
        responseCode: number,
        responseBody: string,
        details?: { reason: string }
      ) => {
        const err = new Error(
          `Unexpected status code tracking event ${name} to amplitude: ${responseCode} (${responseBody} ${details?.reason})`
        );
        reject(err);
      }
    );
  }).catch((err) => {
    console.error(err);
    captureException(err);
  });
};

const getHash = () => {
  if (window.location.hash) {
    return window.location.hash;
  }
  try {
    const href = performance.getEntriesByType('navigation')[0].name;
    const parts = href ? href.split('#') : [];
    return parts.length === 2 ? parts[1] : undefined;
  } catch (err) {
    return undefined;
  }
};

const getTextSnippets = () => {
  const hash = getHash();
  if (hash && hash.startsWith(':~:')) {
    const snippets = hash.substring(3).split('&');
    return snippets
      .map((s) => s.split('=')[1])
      .map((s) => decodeURIComponent(s));
  }
  return undefined;
};

export const initAmplitude = () => {
  const deviceId = window.env.DEVICE_ID;
  const userId = window.env.USER_ID;

  const API_KEY = window.env.AMPLITUDE_API_KEY;
  if (!API_KEY) throw new Error(`Missing AMPLITUDE_API_KEY`);
  const config: amplitude.Config = {
    includeFbclid: true,
    includeGclid: true,
    includeReferrer: true,
    includeUtm: true,
    deviceId,
  };
  if (isProd) config.apiEndpoint = 'amp.syncwith.com';

  amplitude.getInstance().init(API_KEY, undefined, config);
  // seems like we can't set this in config (or we can, but it gets ignored???)
  if (userId) amplitude.setUserId(userId);

  // If we don't have a deviceId for the user that means we've never seen them on web
  // If we don't have a user id, then it means they are not an existing user from the addon (for example)
  if (!userId && amplitude.getInstance().isNewSession()) {
    setOnce({ firstApp: WebAppName, firstWebApp: getApp() });
  }

  const firstPageViewInSession = amplitude.getInstance().isNewSession();
  trackAmplitudeEvent('pageview', {
    path: window.location.pathname,
    hash: getHash(),
    textSnippets: getTextSnippets(),
    queryString: window.location.search,
    app: WebAppName,
    webapp: getApp(),
    bot: !!window.env.IS_BOT,
    firstPageViewInSession,
    ...(firstPageViewInSession && { referrer: document.referrer }), // referrer doesn't make sense after you've clicked around our site
  });
};
