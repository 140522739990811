import {
  BrowserTracing,
  init,
  remixRouterInstrumentation,
  setUser,
  User,
} from '@sentry/remix';
import { useEffect } from 'react';
import { useMatches, useLocation } from '@remix-run/react';
import { IdentifyUser } from './event-tracker.client';
import { beforeSend, SENTRY_ADDON_DSN } from './sentry';
import { getEnv } from './env';

export const initSentry = (
  userId: string | undefined,
  userEmail: string | undefined
) => {
  const environment = getEnv('NODE_ENV');
  init({
    enabled: environment === 'production',
    beforeSend,
    dsn: SENTRY_ADDON_DSN,
    environment: getEnv('NODE_ENV'),
    tracesSampleRate: 1,
    sendDefaultPii: true,
    normalizeDepth: 6, // stringify deeper objects
    integrations: [
      new BrowserTracing({
        routingInstrumentation: remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches
        ),
      }),
    ],
  });

  if (userId || userEmail) {
    setUser({
      ...(userId && { id: userId }),
      ...(userEmail && { email: userEmail }),
    });
  }
};

export const identifySentry = (auth: IdentifyUser | undefined) => {
  let sentryUser: User | null = null;
  if (auth) {
    sentryUser = {
      id: auth.id,
    };
    // avoid setting email at all if we don't have one, for cleaner data at Sentry, I think
    if (auth.email) {
      sentryUser.email = auth.email;
    }
  }
  setUser(sentryUser);
};
